import Service from '@/helpers/service'

export default {

    get(params) {
        return Service().get('jabatan', {params});
    }, 
    show(id) {
        return Service().get('jabatan/show/'+id);
    },    
    create(params) {
        return Service().post('jabatan/create', params);
    },
    update(id, params) {
        return Service().post('jabatan/update/'+id, params);
    },
    delete(id) {
        return Service().post('jabatan/delete/'+id);
    }
}